/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.scrollable-div {
  max-height: 400px;
  overflow-y: auto;
  border-width: 0;
  border-radius: 12px;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
}

.my-card {
  margin: 10px 0;
  border-width: 0;
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.user-icon {
  font-size: 24px;
  text-align: center;
}

.touch-bar {
  display: flex;
  align-items: center;
  height: 20px;
  /* background-color: gray ; */
  background-color: rgb(128 128 128 / 0.25); /* 50% transparent */
  /* opacity: 0.5; */
  border-radius: 3px;
  /* border: 1px solid #ccc; */
}

.individual-touch {
  /* height: 100%;
  text-align: center; */
  /* background-color: #4285F4; */
  /* color: white;
  padding: 2px;
  border-radius: 12px;
  border-right: 1px solid white;
  transition: all 0.3s ease; */
  /* transition: background-color 0.3s; */

  /* flex: 1; */
  height: 20px;
  border-radius: 3px;
  /* background-color: lightblue; */
  /* margin-right: 1px; */
  /* margin: 0 1px; */
  transition: transform 0.2s, box-shadow 0.2s;
}

.highlight {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* z-index: 2; */
  transform: scale(1.1);
  /* background-color: #327ad5; */
}

.dull {
  background-color: #92a8d1;
}

.my-tooltip {
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  border-width: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.tooltip-content {
  font-size: 12px;
  font-weight: bold;
}
.main-wrapper {
    overflow-y: auto;
    overflow-x: visible;
    /* height: 100vh; */
    /* right: 0; */
    /* padding-right: 0px; */
    /* padding-bottom: 25px; */
}

.main-wrapper::-webkit-scrollbar {
    width: 10px;
    position: fixed;
    right: 0;
    /* bottom: 25px; */
    /* padding-right: 25px; */
}

.main-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.main-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.main-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.main-wrapper::-webkit-scrollbar:horizontal {
    height: 10px;
    position: fixed;
    bottom: 0;
}


.sidebar-wrapper::-webkit-scrollbar {
    width: 10px;
    position: fixed;
    right: 0;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.sidebar-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.sidebar-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sidebar-wrapper::-webkit-scrollbar:horizontal {
    height: 10px;
    position: fixed;
    bottom: 0;
}


.sidebar .legal-documents {
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
}

.sidebar .legal-documents ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar .legal-documents li {
    margin: 0 5px;
}

.sidebar .legal-documents .separator {
    margin: 0 5px;
}

/* .sidebar .legal-documents .mini-legal-documents {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
} */

.mini-legal-documents {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px 10px 15px;
    border-radius: 4px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
}

.mini-legal-documents:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.13);
}



/* .main-panel::-webkit-scrollbar {
    width: 10px;
    position: fixed;
    right: 0;
}

.main-panel::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.main-panel::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.main-panel::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.main-panel::-webkit-scrollbar:horizontal {
    height: 10px;
    position: fixed;
    bottom: 0;
} */



.loading-spinner-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent black */
    z-index: 9999;
    /* to make sure it appears on top */
}


.creating-walkbook-map-container {
    position: relative;
    width: 100%;
    height: 100%;
}


.loading-backend-spinner-overlay {
    /* position: absolute; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    /* This gives a slightly transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* This ensures the overlay is on top of other content */
}

.loading-backend-spinner-card {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 8px solid #000;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} */


.login-with-google-btn {
    max-width: 250px;
    transition: background-color .3s, box-shadow .3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}

.login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-with-google-btn:active {
    background-color: #eeeeee;
}

.login-with-google-btn:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}


.click-tr-item {
    color: black;
    background-color: white;
}

/* Used to click on tr items of tables */
.click-tr-item:hover {
    background-color: #aaaaab !important;
    cursor: pointer !important;
}

/* Used to click on tr items of tables */
.hover-tr-item:hover {
    background-color: #aaaaab !important;
    cursor: default;
}

/* Used to click on listgroup items */
.click-list-item:hover {
    border: 1px solid #282c34;
    cursor: pointer;
}














.login-btn {
    max-width: 250px;
    transition: background-color .3s, box-shadow .3s;
    /* padding: 0; */

    padding: 12px 24px 12px 24px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    /* background-color: rgb(65, 168, 236); */
    background-color: #43AAA6;
    /* background-repeat: no-repeat;  */
    /* background-position: 12px 11px; */
}

.login-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-btn:active {
    background-color: #eeeeee;
}

.login-btn:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.off-login-btn {
    cursor: not-allowed;
    max-width: 250px;
    transition: background-color .3s, box-shadow .3s;
    /* padding: 0; */

    padding: 12px 24px 12px 24px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    /* background-color: rgb(65, 168, 236); */
    background-color: #43AAA6;
    /* background-repeat: no-repeat;  */
    /* background-position: 12px 11px; */
}

.off-login-btn:hover {
    cursor: not-allowed;
    padding: 12px 24px 12px 24px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    background-color: #43AAA6;
}

.off-login-btn:active {
    cursor: not-allowed;
    padding: 12px 24px 12px 24px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    background-color: #43AAA6;
}

.off-login-btn:focus {
    cursor: not-allowed;
    padding: 12px 24px 12px 24px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    background-color: #43AAA6;
}


.card-footer-btn {
    max-width: 250px;
    transition: background-color .3s, box-shadow .3s;

    padding: 12px 24px 12px 24px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-color: #68BEE2;
}

.card-footer-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0), 0 2px 4px rgba(0, 0, 0, .25);
}

.card-footer-btn:active {
    background-color: #eeeeee;
}

.card-footer-btn:disabled {
    color: #ffffff;
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: #68BEE2;
}

.card-footer-btn:disabled:hover {
    /* cursor: not-allowed;
    pointer-events: all !important; */

    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    background-color: #68BEE2;
}

.card-footer-btn:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}


.form-control-box:disabled {
    pointer-events: none;
}

.address-card-body-collapse {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0), 0 2px 4px rgba(0, 0, 0, .25);
}













.login-forms {
    border-top: solid rgba(185, 185, 185, 0.521) 6px,
}

.login-google {
    border-top: solid rgba(185, 185, 185, 0.521) 6px,
}



/* .scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

}
.scrolling-wrapper-flexbox .board-list {
    flex: 0 0 auto;
    margin-right: 3px;
}
.scrolling-wrapper-flexbox {
    height: 80px;
    margin-bottom: 20px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper-flexbox::-webkit-scrollbar {
    display: none;
} */

.list-scrolling-wrapper {
    /* background-color: #dfe3e6;
    border-radius: 3px;
    width: 275px;
    height: auto;
    max-height: 400px;
    margin: 0 3px 10px 3px;
    justify-content: center;
    align-items: center; */

    /* overflow-y: auto; */

    overflow-x: hidden;
    overflow-y: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.list-scrolling-wrapper::-webkit-scrollbar {
    width: 6px;
    position: fixed;
    right: 0;
}

.list-scrolling-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.list-scrolling-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.list-scrolling-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.board-scrolling-wrapper {
    /* overflow-x: auto; */
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.board-scrolling-wrapper::-webkit-scrollbar {
    width: 6px;
    position: fixed;
    bottom: 0;
}

.board-scrolling-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.board-scrolling-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.board-scrolling-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* .scrolling-wrapper::-webkit-scrollbar-button {
    display: block;
    background-color: #4e4e4e00;
    background-repeat: no-repeat;   
    background-size: 50%;
    background-position: center;
}

.scrolling-wrapper::-webkit-scrollbar-button:horizontal:start:increment {
    height: 20px;
    width: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrolling-wrapper::-webkit-scrollbar-button:horizontal:start:decrement {
    display: none;
}

.scrolling-wrapper::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
}

.scrolling-wrapper::-webkit-scrollbar-button:horizontal:end:decrement {
    height: 20px;
    width: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
} */

/* .address-voter-table {
    margin-top: 25px;
    position: inherit;
    width: 100%;
    height: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
} */

/* .address-voter-table thead {
    text-decoration-line: underline;
} */



.address-voter-table-wrapper {
    /* overflow-x: auto;
    overflow-y: auto; */
    overflow: auto;
    /* scrollbar-width: thin; */
    /* white-space: nowrap; */
    /* -webkit-overflow-scrolling: touch; */
}

.address-voter-table-wrapper::-webkit-scrollbar {
    width: 7px;
    position: fixed;
    right: 0;
}

.address-voter-table-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.address-voter-table-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.address-voter-table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.address-voter-table-wrapper::-webkit-scrollbar:horizontal {
    height: 7px;
    position: fixed;
    bottom: 0;
}

.address-voter-table thead th {
    /* padding-top: 0px !important; */
    padding-bottom: 12px !important;
    z-index: 2;
    background-color: white;
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
    /* text-decoration-line: underline; */
}

.address-voter-table tbody tr {
    position: inherit;
}

/* .address-modal {
    width: 100% !important;
    max-width: 750px !important;
} */

.modal-address-voter-table thead tr th {
    /* padding-top: 0px !important; */
    /* padding-bottom: 12px !important; */
    padding: 12px 4px;
    z-index: 2;
    background-color: white;
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
    /* text-decoration-line: underline; */
}

.modal-address-voter-table tbody tr {
    position: inherit;
}

.modal-address-voter-table tbody tr td {
    padding: 12px 4px;
}

.modal-address {
    /* top: 0;
    position: absolute;
    align-content: initial; */
    width: 96% !important;
    max-width: 750px !important;
    /* z-index: 9999; */
    /* height: 100% !important; */
    /* max-height: 100vh !important; */
    /* align-items: start; */
}



.dashboard-scroll-table-wrapper {
    /* overflow-x: auto;
    overflow-y: auto; */
    overflow: auto;
    /* padding: 3px; */

    /* scrollbar-width: thin; */
    /* white-space: nowrap; */
    /* -webkit-overflow-scrolling: touch; */
}

.dashboard-scroll-table-wrapper::-webkit-scrollbar {
    width: 7px;
    right: 0;
    /* bottom: 15px; */
    position: fixed;

}

.dashboard-scroll-table-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.dashboard-scroll-table-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.dashboard-scroll-table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dashboard-scroll-table-wrapper::-webkit-scrollbar:horizontal {
    height: 7px;
    position: fixed;
    bottom: 15px;
}

/* .dashboard-scroll-table-wrapper::-webkit-scrollbar-horizontal {
    height: 7px;
    position: fixed;
    bottom: 0;
} */


.dashboard-scroll-table thead th {
    padding-top: 0px !important;
    padding-bottom: 3px !important;
    z-index: 2;
    background-color: white;
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
    /* text-decoration-line: underline; */
}

.dashboard-scroll-table tbody tr {
    /* z-index: 2; */
    background-color: white !important;
    position: inherit;
}

.dashboard-scroll-table tbody tr:hover {
    /* color: #aaaaab !important; */
    background-color: #aaaaab !important;
    cursor: pointer !important;
}

/* .dashboard-scroll-table tbody .click-tr {
    position: inherit;
} */

/* .dashboard-scroll-table tbody .click-tr:hover {
    background-color: #aaaaab !important;
    cursor: pointer !important;
} */


.small-table thead tr th {
    font-size: 0.65rem;
}

.small-table tbody tr td {
    font-size: 0.75rem;
    /* Adjust the size as needed */
    padding-top: 4px;
    padding-bottom: 4px;
}





.scroll-table-wrapper {
    /* overflow-x: auto;
    overflow-y: auto; */
    overflow: auto;
    /* scrollbar-width: thin; */
    /* white-space: nowrap; */
    /* -webkit-overflow-scrolling: touch; */
}

.scroll-table-wrapper::-webkit-scrollbar {
    width: 7px;
    position: fixed;
    right: 15;
}

.scroll-table-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 9px;
}

.scroll-table-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.scroll-table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scroll-table-wrapper::-webkit-scrollbar:horizontal {
    height: 7px;
    position: fixed;
    bottom: 15;
}


.scroll-table thead th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    z-index: 2;
    background-color: white;
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
    /* text-decoration-line: underline; */
}

.scroll-table tbody tr {
    position: inherit;
}




/* .rec-walkbooks-scroll-table {
    width: 100%;
    table-layout: fixed;
} */

.rec-walkbooks-scroll-table td,
.rec-walkbooks-scroll-table th {
    /* CSS for the ellipsis addition */
    /* display: inline-block; */
    max-width: 100%;
    /* max-width: max-content; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
    cursor: pointer;
}

.rec-walkbooks-scroll-table td:hover,
.rec-walkbooks-scroll-table th:hover {
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    z-index: 1000;
}

.rec-walkbooks-scroll-table td .map-cell {
    /* Specific cell styling */
    cursor: pointer;
}

.rec-walkbooks-map-cell-icon {
    vertical-align: bottom;
    cursor: pointer;
    color: #7C5AF0;
    font-size: 24px;
    /* Default size */
    transition: color 0.2s, font-size 0.2s;
    /* Smooth transition for color and size */
}

.rec-walkbooks-map-cell-icon:hover {
    color: #6648c7;
    /* Color on hover */
    font-size: 28px;
    /* Larger size on hover */
}

.rec-walkbooks-scroll-table thead th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    z-index: 2;
    background-color: white;
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
    /* text-decoration-line: underline; */
}

.rec-walkbooks-scroll-table tbody tr {
    position: inherit;
}

/* .rec-walkbooks-scroll-table td .map-cell {
    position: inherit;
} */
















.scrollable-div {
    max-height: 400px;
    overflow-y: auto;
    border-width: 0;
    border-radius: 12px;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
}

.my-card {
    /* margin: 10px 0; */
    margin-bottom: 10px;
    border-width: 0;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.user-icon {
    font-size: 24px;
    text-align: center;
}

.touch-bar {
    display: flex;
    align-items: center;
    height: 20px;
    /* background-color: gray ; */
    background-color: rgb(128 128 128 / 0.25);
    /* 50% transparent */
    /* opacity: 0.5; */
    border-radius: 3px;
    /* border: 1px solid #ccc; */
}

.individual-touch {
    /* height: 100%;
    text-align: center; */
    /* background-color: #4285F4; */
    /* color: white;
    padding: 2px;
    border-radius: 12px;
    border-right: 1px solid white;
    transition: all 0.3s ease; */
    /* transition: background-color 0.3s; */

    /* flex: 1; */
    height: 20px;
    border-radius: 3px;
    /* border: 1px dashed rgb(180, 180, 180); */
    /* background-color: lightblue; */
    margin-right: 1px;
    /* margin: 0 1px; */
    transition: transform 0.2s, box-shadow 0.2s;
}

.highlight {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    /* z-index: 2; */
    transform: scale(1.1);
    /* background-color: #327ad5; */
}

.dull {
    background-color: #92a8d1;
}

.my-tooltip {
    background-color: white;
    padding: 10px;
    border-radius: 12px;
    border-width: 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.tooltip-content {
    font-size: 12px;
    font-weight: bold;
}


.dashboard-card {
    margin: 15px;
    border-radius: 20px 20px 20px 20px;
    /* rounded corners */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    /* box shadow */
}

.dashboard-card-header {
    /* border-radius: 20px 20px 0px 0px; */
    background-color: #61AFFE
}

.dashboard-card-body {
    /* border-radius: 0px 0px 20px 20px; */
    background-color: white;
}

.walkbook-dashboard-list-card {
    margin: 10px 0;
    border-width: 0;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.walkbook-dashboard-list-card-header {
    /* border-radius: 12px;
    background-color: rgba(9, 24, 76, 0.3); */
    /* position: sticky; */
    top: 0;
    /* left: 10; */
    z-index: 2;
    min-width: 200px;
    /* padding: 10px; */
    /* padding-top: 10px;
    padding-bottom: 10px; */

}

.filter-options-card {
    margin: 10px 0;
    border-width: 0;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.filter-options-select {
    /* margin: 10px 0; */
    border-width: 0;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.address-voter-list-card {
    margin: 10px 0;
    border-width: 0;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

}

.address-voter-list-card-header {
    /* border-radius: 12px;
    background-color: rgba(9, 24, 76, 0.3); */
    /* position: sticky; */
    top: 0;
    /* left: 10; */
    z-index: 2;
    min-width: 200px;
    /* padding: 10px; */
    /* padding-top: 10px;
    padding-bottom: 10px; */

}



.voter-group-list-card {
    margin: 10px 0;
    border-width: 0;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.voter-group-list-card-header {
    /* border-radius: 12px;
    background-color: rgba(9, 24, 76, 0.3); */
    /* position: sticky; */
    top: 0;
    /* left: 10; */
    z-index: 2;
    min-width: 200px;
    /* padding: 10px; */
    /* padding-top: 10px;
    padding-bottom: 10px; */

}


.voter-group-header-pill {
    background-color: rgb(229, 229, 229);
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 12px;
    padding: 4px 6px;
    display: inline-block;
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.voter-group-header-pill:hover {
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    /* position: absolute; Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}



.voter-list-card {
    margin: 10px 0;
    border-width: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.voter-list-card-header {
    /* border-radius: 12px;
    background-color: rgba(9, 24, 76, 0.3); */
    /* position: sticky; */
    top: 0;
    /* left: 10; */
    z-index: 2;
    min-width: 200px;
    /* padding: 10px; */
    /* padding-top: 10px;
    padding-bottom: 10px; */

}


.voter-header-pill {
    background-color: white;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 12px;
    padding: 4px 6px;
    display: inline-block;
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.voter-header-pill:hover {
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    /* position: absolute; Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}

.row-scroll-card-header {
    width: 100%;
    position: sticky;
    top: 0;
    /* left: 10; */
    z-index: 2;
    /* padding-top: 10px;
    padding-bottom: 10px; */
}

.scroll-card-header {
    /* background-color: rgb(200, 200, 200); */
    /* background-color: rgba(128, 128, 128, 0.5); */
    position: sticky;
    top: 0;
    /* left: 10; */
    /* z-index: 2; */
    min-width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-radius: 20px 20px 0px 0px; */
}

/* .header-pill {
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 4px 6px;
} */


.click-header-pill {
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 4px 6px;
    display: inline-block;
    /* Makes sure padding and other properties work as expected */
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.click-header-pill:active {
    background-color: #7C5AF0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.click-header-pill:hover {
    cursor: pointer !important;
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    /* position: absolute; Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}


.tab-header {
    /* border-radius: 8px; */
    /* color: gray; */
    /* background-color: #F3F4F6; */
    color: #4f4f4f;
    padding: 4px 16px;
    /* Adjusted padding for a tab-like appearance */
    display: inline-flex;
    /* Use inline-flex for centering text */
    justify-content: center;
    /* Center text horizontally */
    align-items: center;
    /* Center text vertically */
    transition: all 0.3s ease;
    border-bottom: 3px solid transparent;
    /* Invisible border for a consistent layout */
    width: 100%;
    /* Ensure it fills the column */
    box-sizing: border-box;
    /* Include padding in width calculation */
}

.tab-header:hover {
    cursor: pointer;
    color: #7C5AF0;
    /* background-color: #F3F4F6; */
    /* border-radius: 8px; */
    /* Hover effect to hint clickability */
}

.tab-header.active {
    /* padding: 2px;
    border-radius: 8px; */
    /* background-color: #ffffff; */
    color: #7C5AF0;
    /* display: block; */
    /* width: 100%; */
    /* Active tab text color */
    border-bottom: 3px solid #7C5AF0;
    /* Horizontal bar below the active tab */
}



/* .tab-header.active:after {
    content : "";
    display: block;
    height  : 3px;
    border-radius: 8px;
    margin: 0 auto;
    width: 100%;
    border-bottom: 3px solid #7C5AF0;
} */



.walkbook-card-tab-header {
    border-radius: 8px;
    /* color: gray; */
    /* background-color: #F3F4F6; */
    color: #4f4f4f;
    padding: 0px 8px;
    /* margin-bottom: 3px; */
    padding-bottom: 3px;
    /* Adjusted padding for a tab-like appearance */
    /* display: inline-flex; */
    /* Use inline-flex for centering text */
    justify-content: center;
    /* Center text horizontally */
    align-items: center;
    /* Center text vertically */
    transition: all 0.3s ease;
    /* border-bottom: 3px solid transparent; */
    /* Invisible border for a consistent layout */
    /* width: 100%; */
    /* Ensure it fills the column */
    width: 100%;
    max-width: 85px;
    /* width: max-content; */
    /* width: 85px; */
    min-width: min-content;
    box-sizing: border-box;
    /* Include padding in width calculation */
}

.walkbook-card-tab-header.active {
    /* padding: 2px;
    border-radius: 8px; */
    /* background-color: #ffffff; */
    color: #7C5AF0;
    /* display: block; */
    /* width: 100%; */
    /* Active tab text color */
    /* border-bottom: 3px solid #7C5AF0; */
    /* Horizontal bar below the active tab */
}

.walkbook-card-tab-header:hover {
    cursor: pointer;
    color: #7C5AF0;
    /* Hover effect to hint clickability */
}

.walkbook-card-tab-header.active:after {
    content: "";
    /* position: absolute; */
    display: block;
    /* left    : 0; */
    /* bottom  : 0; */
    /* height  : 3px; */
    border-radius: 8px;
    /* display: block; This will put the pseudo element on its own line. */
    margin: 0 auto;
    /*This will center the border. */
    width: 100%;
    /* Change this to whatever width you want. */
    border-bottom: 3px solid #7C5AF0;
}




.card-tab-header-wrapper {
    background-color: #F3F4F6;
    border-radius: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: inline-flex;
    /* justify-content: center; */
    /* justify-content: space-between; */
    /* Center text horizontally */
    justify-content: space-evenly;
    align-items: center;
    /* Center text vertically */
    transition: all 0.3s ease;
    /* border-bottom: 3px solid transparent; */
    width: 100%;
    /* width: intrinsic; Safari/WebKit uses a non-standard name */
    /* width: -moz-max-content; Firefox/Gecko */
    /* width: -webkit-max-content; Chrome */
    /* width: max-content; */
    /* width: 200px; */
}

.card-tab-header {
    border-radius: 8px;
    background-color: #F3F4F6;
    /* color: gray; */
    color: #4f4f4f;
    /* padding: 4px 16px; */
    /* padding-top: 4px;
    padding-bottom: 4px; */
    padding-left: 5px;
    padding-right: 5px;
    /* margin-left: 15px;
    margin-right: 15px; */
    /* Adjusted padding for a tab-like appearance */
    /* display: block; */
    /* display: inline-flex; */
    /* Use inline-flex for centering text */
    justify-content: center;
    /* Center text horizontally */
    /* justify-content: space-between; */
    align-items: center;
    /* Center text vertically */
    transition: all 0.3s ease;
    /* border-bottom: 3px solid transparent; */
    /* Invisible border for a consistent layout */
    width: 100%;
    max-width: 85px;
    /* width: max-content; */
    /* width: 85px; */
    min-width: min-content;
    /* Ensure it fills the column */
    /* box-sizing: border-box; */
    /* Include padding in width calculation */
}

.card-tab-header.active {

    /* padding: 2px; */
    /* border-radius: 8px; */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #7C5AF0;
    /* width: 100px; */
    /* Active tab text color */
    /* border-bottom: 3px solid #7C5AF0; */
    /* Horizontal bar below the active tab */
}

.card-tab-header.active::after {
    content: "";
    /* position: absolute; */
    display: block;
    /* left    : 0; */
    /* bottom  : 0; */
    /* height  : 3px; */
    border-radius: 8px;
    /* display: block; This will put the pseudo element on its own line. */
    margin: 0 auto;
    /*This will center the border. */
    width: 90%;
    /* Change this to whatever width you want. */
    border-bottom: 3px solid #7C5AF0;
}

.card-tab-header:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #7C5AF0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    /* Hover effect to hint clickability */
}


.header-pill {
    background-color: white;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 12px;
    padding: 4px 6px;
    display: inline-block;
    /* Makes sure padding and other properties work as expected */
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.header-pill:hover {
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    /* position: absolute; Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}

.walkbook-card-header-pill {
    /* background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px; */
    padding: 4px 6px;
    display: inline-block;
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.walkbook-card-header-pill:hover {
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    /* position: absolute; Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}

.horizontal-divider {
    width: 100%;
    border-top: 1px solid grey;
    /* margin: 3px 0 0; */
    margin: .175rem 0 0;
}

/* .horizontalDivider {
    width: 235px;
    margin: 3px 15px 0px 10px;
}

.sidebarMini .horizontalDivider {
    width: 55px;
}

.sidebarMini:hover .horizontalDivider {
    width: 235px;
} */


.vertical-divider {
    height: 100%;
    border-right: 1px solid black;
    margin: 0 .5rem;

    /* width: 1px;
    background-color: grey;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; */
}


.card-scroll-div-wrapper {
    overflow: auto;
    height: calc(100vh - 50px);
    /* padding-top: 10px; */
    /* max-height: 275px; */
}

.card-scroll-div-wrapper::-webkit-scrollbar {
    width: 7px;
    position: fixed;
    z-index: 2;
    /* right: 15; */
}

.card-scroll-div-wrapper::-webkit-scrollbar-thumb {
    background: #7d7d7d;
    border-radius: 9px;
}

.card-scroll-div-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
}

.card-scroll-div-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.card-scroll-div-wrapper::-webkit-scrollbar:horizontal {
    height: 7px;
    position: fixed;
    /* bottom: 15; */
}







.last-completed-walkbook-card {
    margin: 15px;
    border-radius: 20px 20px 20px 20px;
    border-width: 0;
    /* rounded corners */
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
    /* box shadow */
}

.goalBadgeColor {
    /* background-color: #3F464C !important; */
    background-color: #7C59F0 !important;
    color: white !important;
}

.touchesBadgeColor {
    /* background-color: #3F464C !important; */
    background-color: #4D545B !important;
    color: white !important;
}


.touches-summary .touches-badge {
    display: inline-block;
    margin: 5px;
    padding: 8px;
    font-size: 0.8rem;
    /* Adjust font size for better readability */
    border-radius: 20px;
    /* Pill-shaped badges */
}

.touches-chart {
    /* border: 0px solid #ccc; */
    /* border-radius: 12px; */
    overflow: hidden;
    /* Ensures no overflow from the chart */
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}



.voter-group-card-sections-badge {
    /* background-color: #3F464C !important; */
    background-color: #0a78cc !important;
    /* background-color: #eab120 !important; */
    color: white !important;
}

.user-details-badge {
    /* background-color: #3F464C !important; */
    background-color: #7C59F0 !important;
    color: white !important;
}

.badges-header-pill {
    /* background-color: white;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0); */
    display: inline-block;
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.badges-header-pill:hover {
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    /* position: absolute; Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}



.stats-click-header-pill {
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 4px 6px;
    display: inline-block;
    /* Makes sure padding and other properties work as expected */
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.stats-click-header-pill:active {
    background-color: #7C5AF0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.stats-click-header-pill:hover {
    cursor: pointer !important;
    max-width: max-content;
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* Allows the text to wrap on hover */
    /* position: absolute; Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}

.rec-stats-badge {
    padding: 0px;
    /* border-radius: 7px; */
    background-color: transparent !important;
    /* background-color: var(--badge-bg) !important; */
}

/* .voter-group-card-small-form-group .form-label,
.voter-group-card-small-form-group .form-select,
.voter-group-card-small-form-group .form-control {
    font-size: 0.8rem;
} */

/* .voter-group-card-small-form-group .form-control {
    padding: 0.25rem 0.5rem;
} */

.voter-group-card-small-form-group .form-label {
    font-size: 0.8rem;
}

.voter-group-card-small-form-group .form-control {
    padding: 0.25rem;
    font-size: 0.8rem;
}

.voter-group-card-small-form-group .form-select {
    padding: 0.25rem;
    font-size: 0.8rem;
}

.voter-group-card-small-form-group {
    margin-bottom: 0.5rem;
}




.recommendation-card-info {
    /* display: inline-block; */
    max-width: 100%;
    /* Ensures the pill does not exceed the parent container */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line, works with text-overflow */
    /* white-space: pre-line; */
    /* white-space: pre-wrap; */
    /* white-space: pre; */
    /* white-space: break-spaces; */
    transition: all 0.3s ease;
    /* Adds a transition for smooth expansion */
}

.recommendation-card-info:hover {
    max-width: max-content;
    /* max-width: 100%; */
    /* Allows the element to expand to fit content on hover */
    white-space: normal;
    /* white-space: nowrap; */
    /* Allows the text to wrap on hover */
    /* white-space: pre-line; */
    /* white-space: pre-wrap; */
    /* white-space: pre; */
    /* white-space: break-spaces; */
    /* position: absolute; */
    /* Allows the pill to expand over other elements */
    z-index: 1000;
    /* Ensures the expanded element is above other elements */
}


.voter-pagination-button {
    background: transparent;
    border: none;
    border-radius: 10px;
    padding: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.voter-pagination-button:disabled {
    cursor: default;
}

.voter-pagination-button span {
    vertical-align: bottom;
    font-size: 28px;
}

.voter-pagination-button:not(:disabled):active span {
    color: #afafaf;
}

.voter-pagination-button:disabled span {
    color: #a0a0a0;
}





/* .walkbooks-audit-card {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.walkbooks-audit-card .card-title {
    font-size: 16px;
    color: rgb(107 114 128);
}

.walkbooks-audit-card .total-count {
    text-align: start;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.walkbooks-audit-card .walkbooks-audit-bar {
    display: flex;
    height: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.walkbooks-audit-card .complete-bar {
    background-color: #28a745;
}

.walkbooks-audit-card .in-progress-bar {
    background-color: #ffc107;
}

.walkbooks-audit-card .pending-bar {
    background-color: #dc3545;
}

.walkbooks-audit-card .walkbooks-audit-legend {
    margin-top: 10px;
}

.walkbooks-audit-card .legend-item {
    display: flex;
    align-items: center;
}

.walkbooks-audit-card .color-box {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}



.walkbooks-audit-card .complete-color {
    background-color: #28a745;
}

.walkbooks-audit-card .in-progress-color {
    background-color: #ffc107;
}

.walkbooks-audit-card .pending-color {
    background-color: #dc3545;
} */





.walkbooks-audit-card {
    width: 100%;
    /* max-width: 480px; */
    padding: 20px;
    /* padding: 20px 5px; */
    /* Adjust the width as needed */
    border-radius: 8px;
    border-width: 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}


.show-audit-walkbooks-btn {
    width: 165px;
    height: 40px;
    font-size: 16x;
    background-color: white;
    color: #4a4a4a;
    /* Not fully black */
    border: 1px solid #ddd;
    /* Thin border */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.show-audit-walkbooks-btn:hover {
    color: #4a4a4a;
    /* Grey text on hover and click */
    background-color: rgb(250, 250, 250);
    /* Keep the background white on hover and click */
    border: 1px solid #ddd;
    /* Maintain the border style on hover and click */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* More prominent shadow on hover */
}


.audit-walkbook-alert-btn {
    width: 65px;
    height: 35px;
    font-size: 16x;
    background-color: rgb(225, 53, 53);
    color: #ffffff;
    /* border: 1px solid #ddd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); */
    border: none;
    box-shadow: none;
    border-radius: 8px;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.audit-walkbook-alert-btn:hover {
    color: #ffffff;
    /* Grey text on hover and click */
    background-color: rgb(211, 48, 48);
    /* Keep the background white on hover and click */
    /* border: 1px solid #ddd; */
    border: none;
    /* Maintain the border style on hover and click */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* More prominent shadow on hover */
}



.walkbooks-audit-card .scrollable-container {
    max-height: 200px;
    /* Adjust based on your preference */
    overflow-y: hidden;
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #ccc;
    /* Visual separator, optional */
}


.walkbooks-audit-card .card-title {
    text-align: left;
    font-size: 16px;
    /* color: #808692; */
    color: rgb(107 114 128);
    /* font-weight: bold; */
    margin-bottom: 5px;
}

.number {
    text-align: start;
    /* Center the number under the title */
    margin-bottom: 15px;
    /* Space between number and progress bar */
    font-size: 30px;
    font-weight: bold;
}

.walkbooks-audit-bar {
    margin-bottom: 20px;
    height: 15px;
    border-radius: 12px;
    /* width: 80% */
    /* Space between progress bar and legend */
}

.legend {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    /* align-items: start; */
    flex-wrap: wrap;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.box {
    width: 14px;
    /* Adjust size as needed */
    height: 14px;
    /* Adjust size as needed */
    display: inline-block;
    margin-right: 5px;
    border-radius: 3px;
}

.complete {
    background-color: #198755;
    /* Bootstrap success color */
}

.in-progress {
    background-color: #7a00cb;
    /* Bootstrap warning color */
}

.pending {
    background-color: #ffc107;
    /* Bootstrap danger color */
}

.positive {
    background-color: #198755;
    /* Bootstrap success color */
}

.neutral {
    background-color: #7a00cb;
    /* #4e79a7 */
    /* Bootstrap warning color */
}

.negative {
    background-color: #e15759;
    /* #e15759 */
    /* Bootstrap danger color */
}

.not-home {
    background-color: #9c755f;
    /* #9c755f */
    /* Bootstrap danger color */
}

.passed {
    background-color: #bab0ab;
    /* #bab0ab */
    /* Bootstrap success color */
}

.modal-audit-walkbook-alert {
    /* top: 0;
    position: absolute;
    align-content: initial; */
    /* position: relative; */
    /* top: -300px; */
    width: 96% !important;
    max-width: 650px !important;
    margin-top: -265px !important;
    /* height: 100% !important; */
    /* z-index: 9999; */
    /* height: 100% !important; */
    /* max-height: 100vh !important; */
    /* align-items: start; */
}


.user-info-card {
    /* background-color: #f8f9fa; */
    background: transparent;
    /* Light grey background */
    color: #343a40;
    /* Dark grey text */
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* Slight shadow for depth */
}

.user-info-item label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.user-info-item p {
    background: transparent;
    /* background-color: #ffffff; */
    /* White background for contrast */
    padding: 10px;
    border-radius: 5px;
    /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); */
    /* Inner shadow for depth */
}


.campaign-info-card {
    /* background-color: #f8f9fa; */
    background: transparent;
    /* Light grey background */
    color: #343a40;
    /* Dark grey text */
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* Slight shadow for depth */
}

.campaign-info-item label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.campaign-info-item p {
    background: transparent;
    /* background-color: #ffffff; */
    /* White background for contrast */
    padding: 10px;
    border-radius: 5px;
    /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); */
    /* Inner shadow for depth */
}



.dashboard-summary-card {
    overflow: hidden;
    border-radius: 8px;
    margin: 0;
    border-width: 0;
    max-height: 525px;
    height: auto;
    /* max-width: calc(100%-10px); */
    padding: 0px;
    /* padding-left: 0px;
    padding-right: 0px; */
}


.dashboard-summary-card .scroll-div-wrapper {
    /* overflow: auto; */
    /* height: calc(100vh - 50px); */
    /* padding-top: 10px; */
    /* max-height: 275px; */
    max-height: 525px;
    overflow-y: auto;
    /* max-height: 100%; */
}

.dashboard-summary-card .scroll-div-wrapper::-webkit-scrollbar {
    width: 7px;
    /* position: fixed;
    z-index: 2; */
    /* right: 15; */
}

.dashboard-summary-card .scroll-div-wrapper::-webkit-scrollbar-thumb {
    background: #7d7d7d;
    border-radius: 9px;
}

.dashboard-summary-card .scroll-div-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
    margin-top: 5px;
    margin-bottom: 12px;
    margin-right: 0px;
}

.dashboard-summary-card .scroll-div-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}




.dashboard-summary-info-card {
    width: 100%;
    padding: 0px;
    overflow: hidden;
    /* Hide overflow here */
    border-radius: 8px;
    border-width: 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-summary-info-card .scroll-wrapper {
    overflow-x: auto;
    /* Enable horizontal scrolling */
    max-width: 100%;
    /* max-width: calc(100% - 10px); */
    /* margin: 0 5px 0 5px; */
    /* padding: 0 10px; Additional padding can be adjusted here */
}


.dashboard-summary-info-card .scroll-wrapper::-webkit-scrollbar {
    width: 7px;
}

.dashboard-summary-info-card .scroll-wrapper::-webkit-scrollbar-thumb {
    background: #7d7d7d;
    border-radius: 9px;
}

.dashboard-summary-info-card .scroll-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 9px;
    margin-bottom: 10px;
    /* Push the track up */
    margin-left: 5px;
    /* Push the track left */
    margin-right: 5px;
}

.dashboard-summary-info-card .scroll-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dashboard-summary-info-card .scroll-wrapper::-webkit-scrollbar:horizontal {
    height: 7px;
    /* width: calc(100% - 10px); */
}


.dashboard-summary-info-card .card-title {
    text-align: left;
    font-size: 16px;
    /* color: #808692; */
    color: rgb(107 114 128);
    /* font-weight: bold; */
    margin-bottom: 5px;
}


.campaign-member-row {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    /* border-bottom: 1px solid #e0e0e0; */
    /* Visual separator */

}

.campaign-member-row:hover {
    background-color: rgb(232, 232, 232); /* Adjust the color as needed */
}

/* Styles for the delete icon */
.campaign-member-delete-icon {
    color: #bdbdbd;
    margin-left: 10px;
    cursor: pointer;
    /* Color changes to red on hover */
}

.campaign-member-delete-icon:hover {
    color: red;
    /* Color changes to red on hover */
}


.campaign-selection-card {
    width: 100%;
    /* max-width: 480px; */
    padding: 20px;
    /* padding: 20px 5px; */
    /* Adjust the width as needed */
    border-radius: 8px;
    border-width: 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.campaign-selection-card .card-title {
    text-align: left;
    font-size: 16px;
    /* color: #808692; */
    color: rgb(107 114 128);
    /* font-weight: bold; */
    margin-bottom: 5px;
}

.campaign-selection-card:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}





.terms-card-scroll-div-wrapper {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 650px;
    /* width: 475px; */
}

.terms-card-scroll-div-wrapper::-webkit-scrollbar {
    width: 7px;
    position: fixed;
    right: 0;
}

.terms-card-scroll-div-wrapper::-webkit-scrollbar-thumb {
    background: #7d7d7d;
    border-radius: 9px;
}

.terms-card-scroll-div-wrapper::-webkit-scrollbar-track {
    background-color: rgba(170, 170, 170, 0.616);
    border-radius: 0px;
}

.terms-card-scroll-div-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.terms-card-scroll-div-wrapper::-webkit-scrollbar:horizontal {
    height: 7px;
    /* width: calc(100% - 10px); */
}




.terms-button-enabled {
    background-color: #2a76f8;
    color: white;
    font-size: medium;
    padding: 5px 15px;
    border-radius: 10px;
    border: none;
    opacity: 1;
}

.terms-button-disabled {
    background-color: rgb(71, 71, 71);
    color: white;
    font-size: medium;
    padding: 5px 15px;
    border-radius: 10px;
    border: none;
    opacity: 0.5;
}

.terms-button-accepted {
    background-color: rgb(50, 182, 14);
    color: white;
    font-size: medium;
    padding: 5px 15px;
    border-radius: 10px;
    border: none;
    opacity: 0.65;
}


.terms-pagination-button {
    background: transparent;
    border: none;
    border-radius: 10px;
    padding: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.terms-pagination-button:disabled {
    cursor: default;
}

.terms-pagination-button span {
    vertical-align: bottom;
    font-size: 28px;
}

.terms-pagination-button:not(:disabled):active span {
    color: #afafaf;
}

.terms-pagination-button:disabled span {
    /* color: #a0a0a0; */
    color: transparent;
    visibility: hidden;
}



.legal-document-card {
    box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
    border-radius: 10px;
    padding-top: 0px;
    padding-bottom: 5px;
    transform: translate3d(0, 0px, 0);
    transition: all 375ms ease-out;
    opacity: 1;
}

.legal-document-card-hidden {
    opacity: 0;
    transform: translate3d(0, -75px, 0);
}

#map{
  position: sticky;
  /* position: relative; */
  width:100%;
  height: 100vh;
  max-height: 700px;
  /* max-height: 600px; */
  /* max-width: 1000px; */
  /* border-radius: 4px; */
}

#vtd-map-filter{
  /* position: sticky; */
  position: relative;
  width:100%;
  /* max-width: 900px; */
  height: 100vh;
  /* max-height: 625px; */
  max-height: 525px;
  /* border-radius: 4px; */
  /* padding-left: 12px;
  padding-right: 12px; */
}

#filter-map-display{
  position: relative;
  width:100%;
  max-width: 350px;
  height: 100vh;
  max-height: 350px;
}

#strategy-map-display{
  position: relative;
  width:100%;
  max-width: 300px;
  height: 100vh;
  max-height: 300px;
}

/* #map canvas {
  cursor: crosshair;
} */

#mobile-map{
  position: sticky;
  /* position: relative; */
  width:100%;
  height: 100vh;
  max-height: 490px;
  max-width: 1000px;
  /* border-radius: 4px; */
}

/* #mobile-map canvas {
  cursor: crosshair;
} */

/* .map-container {
  width: 100vw;
  height: 100vh;
  max-height: 750px;
} */

.dashboard-map-container {
  width: 100vw;
  height: 100vh;
  /* max-height: 375px; */
}

#last-completed-walkbook-map {
  position: sticky;
  /* position: relative; */
  /* width:100%;
  height: 100vh; */
  max-height: 115px;
  max-width: 200px;
  border-radius: 12px;
}

#voter-map {
  /* position: sticky; */
  position: relative;
  width:100%;
  height: 100vh;
  max-height: 525px;
  /* max-width: 1000px; */
  /* border-radius: 4px; */
}


#road-sign-placement-map {
  /* position: sticky; */
  position: relative;
  width:100%;
  height: 100vh;
  /* max-height: 525px; */
  /* max-width: 1000px; */
  /* border-radius: 4px; */
}

/* #voter-map canvas {
  cursor: crosshair;
} */

/* .voter-map-container {
  width: 100vw;
  height: 100vh;
  max-height: 750px;
} */


#leaflet-map{
  position: sticky;
  /* position: relative; */
  /* width:100%;
  height: 100vh; */
}

.county-leaflet-container {
  /* position: static; */
  /* position: relative; */
  /* position: inherit; */
  width: 100%;
  height: 100vh;
  /* max-width: 950px; */
  max-height: 450px;
  margin: 0;
}

.leaflet-container {
  /* position: static; */
  /* position: relative; */
  /* position: inherit; */
  width: 100%;
  height: 100vh;
  max-height: 850px;
  margin: 0;
}



/* .leaflet-container {
  width: 100%;
  height: 100vh;
  margin: 0;
} */
/* .leaflet-container {
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 700px;
  max-height: 700px;
  margin: 0;
} */
  
path.leaflet-line {
  stroke: #50622b;
  stroke-width: 2;
}

div.leaflet-edge {
  background-color: #95bc59;
  box-shadow: 0 0 0 2px white, 0 0 10px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  cursor: move;
  outline: none;
  transition: background-color 0.25s;
}

div.leaflet-edge.disabled {
  pointer-events: none;
  background-color: #bbb;
}

path.leaflet-polygon {
  fill: #b4cd8a;
  stroke: #50622b;
  stroke-width: 2;
  fill-opacity: 0.75;
}

.map.mode-create {
  cursor: crosshair;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 40px;
}

/* .mapbox-gl-draw_ctrl-draw-btn .mapbox-gl-draw_trash .map-draw-btn {
  background: #ededed;
  border: 1px solid #ccc;
  padding: 10px 30px;
  border-radius: 3px;
  cursor: pointer;
} */

/* .mapbox-gl-draw_ctrl-draw-btn .mapbox-gl-draw_trash .map-draw-btn:focus {
  background: #e5e5e5;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
} */
/* .mapbox-gl-draw_ctrl-draw-btn .mapbox-gl-draw_trash .map-draw-btn:active {
  background: #e5e5e5;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
} */